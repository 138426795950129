.product-card {
  /* width: 18rem; */
  box-shadow: 0 0 5px 0 rgba(22, 22, 26, .25);
  text-align: center;
}
/* .product-card:hover {
  box-shadow: 0 0 5px 3px rgba(22, 22, 26, .25);
} */

@media (max-width: 768px) {
  .product-card {
    border: none !important;
    box-shadow: none;
  }
}