header {
  font-size: 1.1rem;
  font-weight: bold;
  background-image: url('assets/main/background.jpg');
  background-color: #cccccc;
  min-height: 200px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* color: white; */
}

header .header-text {
  background-color: rgba(255, 255, 255, 0.5);
}

@media only screen and (max-width: 600px) {
  header {
    height: auto;
  }
  .navbar-collapse {
    background-color: rgba(255, 255, 255, 0.5);
    text-align: center;
  }
}