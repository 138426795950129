.image-container {
  position: relative;
  display: inline-block;
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7); /* You can adjust the opacity here */
  overflow: hidden;
  height: 0;
  transition: 0.5s ease;
}

.image-container:hover .overlay {
  height: 100%;
}

.inner-text {
  text-align: center;
}

.overlay .inner-text {
  color: white;
  width: 90%;
  /* font-size: 1.2em; */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
